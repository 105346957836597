@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,400,500,700,900");
* {
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  color: #7e7e7f;
  background-color: #eaebee;
  background-color: #edf0f5;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif !important;
}

a,
button {
  text-decoration: none;
  color: #4665a1;
  cursor: pointer;
  border: 0;
  background: transparent;
  outline: inherit;
}

.post-content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  max-width: 400px;
  min-width: 400px;
  margin: 0 auto;
  border: 1px solid #dddfe2;
  border-radius: 15px;
  padding: 20px 0px 0 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.post-content .post-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 15px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.post-content .post-header .post-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; */
}

.post-content .post-header .post-author img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50px;
  border: 1px solid #f9f9f9;
  margin-right: 10px;
}

.icons-div {
  flex-direction: row;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  align-content: center !important;
}

.blue {
  background-color: #3f4d67 !important;
}

.post-fav {
  margin-top: 10px;
  color: #7e7e7f;
  box-shadow: 0px 0px !important;
}

.body-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 93%;
}

.post-content .post-header .post-author .author-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.post-content .post-header .post-author .author-name name {
  font-weight: 700;
  margin-bottom: 5px;
}

.post-content .post-header .post-author .author-name timestamp a {
  color: #7e7e7f;
  font-weight: 100;
  /* width: 95%; */
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-content .post-header .post-author .author-name timestamp svg {
  width: 10px;
  height: 10px;
  margin: 0 2px;
}

.post-content .post-header .post-option {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background: #3f4d67;
  display: inline-block;
  position: relative;
  margin-right: 5px;
  margin-top: 5px;
}

.post-content .post-header .post-option svg {
  width: 25px;
  height: 25px;
  fill: #fff;
  stroke: #fff;
  stroke-width: 0.5;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-52.5%, -40%);
  -ms-transform: translate(-52.5%, -40%);
  transform: translate(-52.5%, -40%);
}

.post-content .post-body .post-option svg {
  stroke: #7e7e7f;
}

.post-content .post-body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 20px 0 10px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.post-content .description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.nomargin {
  margin: 0%;
}

.sub {
  font-weight: normal;
  color: #7e7e7f;
  font-size: 13px;
}

.footerDescription {
  margin-top: 0%;
}

.post-content .video {
  margin-top: 10px;
  width: 100%;
  height: 300px;
}

.post-content .post-body .app-body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
  margin: 20px 0 10px 20px;
}

.post-content .post-footer {
  margin-bottom: 20px;
  padding: 20px 10px 0px 10px;
}

.post-content .buy-footer .buy-footer-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ececec;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  margin-top: -10px;
  padding: 0px 10px;
  font-weight: bold;
  color: #000;
}

.post-content .buy-footer .buy-footer-wrapper .description {
  max-width: 100%;
}

.post-content .buy-footer .buy-footer-wrapper .shopNow {
  background-color: #7e7e7f4d;
  padding: 5px;
  color: #000;
  border: 1px solid#80808085;
  border-radius: 7px;
}

.readmoreDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: auto;
  margin-right: 20px;
  margin-top: 10px;
}

.readMoreButton {
  background-color: #7e7e7f4d;
  padding: 5px 15px;
  color: #000;
  border-radius: 0px;
}

.post-content .post-footer .count-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-weight: 500;
}

.post-content .post-footer .count-wrapper .likes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}

.post-content .post-footer .count-wrapper .likes .like-ico {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: #4080ff;
  display: inline-block;
  position: relative;
  margin-right: 5px;
  margin-top: -2px;
}

.post-content .post-footer .count-wrapper .likes .like-ico svg {
  width: 13px;
  height: 13px;
  fill: #fff;
  stroke: #4080ff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -60%);
  -ms-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
}

.post-content .post-footer .count-wrapper .comment-heard span {
  margin-left: 10px;
}

.card-video-wrapper {
  min-height: 100%;
  max-width: 100%;
  background: black;
  display: flex;
  width: 500px !important;
  justify-content: center;
}

.chart-paper {
  border-radius: 0px !important;
  padding: 10px;
}

.chart-title {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 550px) {
  .post-content {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    max-width: 550px;
    margin: 0 auto;
    padding: 20px 15px 0 15px;
  }

}

@media (max-width: 1100px) {
  .chart-paper {
    /* display: none; */
    height: 500px !important;
  }
}

.border {
  border: 0 !important;
  border-bottom: grey solid 1px !important;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 0 !important;
  width: 99%;
}

.contianerCustom {
  display: flex;
  flex-direction: row;
}

.disable-label {
  top: unset !important;
  left: unset !important;
  position: relative !important;
  transform: unset !important;
  padding-bottom: 10px !important;
}

.card-custom {
  display: flex;
  z-index: 100;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  display: none;
}

.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
  margin-right: 2vw;
  align-items: center;
}

.card-contianer {
  width: 80vw !important;
  justify-content: space-around;
  margin-left: 1vw;
  padding: 10px;
  flex-direction: row !important;
}

.image-card {
  height: 150px !important;
  flex-wrap: wrap;
  background-color: red;
}

.card-grid {
  margin-top: 30px;
  width: 30%;
}

.card-folder {
  margin-top: 0;
  width: 100% !important;
  min-height: 400px;
  -webkit-box-shadow: 1px 0px 22px 0px rgba(50, 50, 50, 0.56) !important;
  -moz-box-shadow: 1px 0px 22px 0px rgba(50, 50, 50, 0.56) !important;
  box-shadow: 1px 0px 22px 0px rgba(50, 50, 50, 0.56) !important;
}

.card-middle {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center ;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1578px) {
  .card-grid {
    margin-left: 10vw !important;
  }
  .card-middle{
    width: 80% !important;
   
  }
}

@media only screen and (max-width: 1386px) {
   
  .card-middle{
    width: 80% !important;
  }
}

@media only screen and (max-width: 1256px) {
   
  .card-middle{
    width: 100% !important;
    flex-wrap: wrap !important;
  }
}
@media only screen and (max-width: 1182px) {
  .card-grid {
    width: 400px;
  }
  .card-middle{
    width: 100% !important;
  }
}
@media only screen and (max-width: 1100px) {
 
  .card-middle{
    width: '130%' !important;
  }
}
@media only screen and (max-width:1060px) {
 
  .card-middle{
  
  }
}
.card-middle-grid{
  -webkit-box-shadow: 0px -1px 14px 0px rgba(122, 122, 122, 0.76);
-moz-box-shadow:    0px -1px 14px 0px rgba(122, 122, 122, 0.76);
box-shadow:         0px -1px 14px 0px rgba(122, 122, 122, 0.76);
width: 300px !important;
height: 250px;
  padding: 0 !important;
}

.second-mid{
  padding: 0 !important;
  height: 100%;
}

.curcle{
  border: white 6px solid;
  display: flex;
  align-self: center;
  width: 80px;
  height: 80px;
  border-radius: 160px;
  justify-content: center;
  align-items: center;
  margin-top: -55px;
  background-color: #5874a7;
  color:white;
  font-weight: bold;
  font-size: 18px;
}

@media only screen and (max-width: 1072px) {
   
  .card-middle{
    width: 100% !important;
    flex-direction: column;
    margin-top: 20px;
    height: auto;
    justify-content: center;
    align-items: center !important;
    margin-right: 20px !important;
  }
  .card-middle-container{
    margin-top: 30px !important;
    width: 50% !important;
  }
  .card-middle-grid{
    margin-bottom: -85px !important;
  }
}


@media only screen and (max-width: 1479px) {
   
  .card-middle{
    width: 100% !important;
 
  }
  .card-middle-container{
    width: 80% !important;
  }
 
}

@media only screen and (max-width: 1229px) {
  .card-middle{
    width: 100% !important;
    flex-direction: column;
    margin-top: 20px;
    height: auto;
    justify-content: center;
    align-items: center !important;
    margin-right: 20px !important;
  }
  .card-middle-container{
    margin-top: 30px !important;
    width: 50% !important;
  }
  .card-middle-grid{
    margin-bottom: -85px !important;
  }
 
}


@media only screen and (max-width: 600px) {
  .card-grid {
    width: 80%;
    min-width: 400px;
  }
  .card-contianer {
    justify-content: center;
  }
  .card-sm {
    margin-left: 6% !important;
    width: 400px !important;
  }
}

@media only screen and (max-width: 411px) {
  .card-sm {
    margin-left: 12% !important;
    width: 400px !important;
  }
}

.grey {
  color: grey !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.button-hover:hover {
  background-color: #f4f4f4;
  padding: 1px;
  cursor: pointer;
}
.line-button {
  width: 90%;
  margin-left: 5%;
}

.border {
  border: black 1px solid;
  border-radius: 5px;
}

.justify-center {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.deletedIcon {
  font-size: 24px;
  align-self: flex-end;
  margin-right: 5%;
}

.dragg {
  display: none !important;
}

.vidCOntainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
}
.image-box {
  width: 45%;
  height: 150px;
  margin: 5px;
  background-color: rgb(211, 211, 211);
  object-fit: cover;
}
.video-box {
  width: 100%;
  height: 500px;
  margin: 5px;
  background-color: black;
  /* object-fit: cover ; */
}

.count-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}
.post-footer {
  margin: 0 !important;
  padding: 0 !important;
  padding: 10px !important;
}

.icons-div {
  flex-direction: row;
}

.row-around {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 90%;
  align-items: center;
  margin-left: 40px;
}

.drawer-lg {
  background-color: red;
}
.bold {
  font-weight: bold !important;
}

.lower {
  display: flex;
  align-self: flex-start;
  margin-left: 18px !important;
  font-size: 24px !important;
  font-weight: bold !important;
  color: #1a4163;
  width: 95%;
  border-bottom: #1a4163 0.5px solid;
  margin-top: 20px !important;
}

.paper-analytics {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.analytics-number {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.analytics-circle {
  border: 1px solid #abababf1;
  border-radius: 10%;
  padding: 15px 10px ;
  text-align: center;
  background-color: #5874a7;
  color:white;
}

/* .analytics-circle-inner {
  border: 1px solid #abababf1;
  border-radius: 100%;
  padding: 6px;
  text-align: center;
  background-color: white;
} */

.grid-ana {
  margin-left: 20px !important;
}

.slider-main {
  width: 85%;
  margin-left: 6%;
}

.justify-btw {
  justify-content: space-between;
}

.long_text  .MuiChip-label{
  white-space: normal !important;
  text-align: center !important; 
}

.percent {
  margin: 0;
  padding: 0;
}

.shadow-select {
  -webkit-box-shadow: 1px 3px 2px 2px #ceffce !important;
  box-shadow: 1px 3px 2px 6px #d3ffd3 !important;
}

.card_prices {
  /* style={{ width: "165px", marginRight: "6px", display:'flex', flexDirection:'column', flexWrap:'wrap' }} */
  width: "165px";
  margin-right: 6px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.containeroverflow {
  margin-left: 6px !important;
}

@media only screen and (min-width: 1300px) {
  .containeroverflow {
    width: 95% !important;
  }
}

@media only screen and (max-width: 1448px) {
 
}
@media only screen and (max-width: 900px) {
  .top-chart {
    background-color: red;
  }
  .main_contianer{
    align-items: center !important;
    margin: auto;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
  }
  .render_card {
  margin: auto;
  }
}
@media only screen and (max-width: 1228px) {
  .legneds {
  }
  .crat-donut {
    /* width: 200px !important; */
  }
}
.legneds {
  /* background-color: orangered; */
  width: 50% !important;
  margin-top: -80px;
  margin-right: -50px;
}

@media only screen and (min-width: 1400px) {
  .containeroverflow {
    width: 90% !important;
  }
}
@media only screen and (min-width: 1450px) {
  .containeroverflow {
    width: 86% !important;
  }
}

@media only screen and (max-width: 1450px) {
  .card-container {
    width: 1300px !important;
    align-items: center;
    justify-content: center;
  }
  .card-container2 {
    margin-right: 5px !important;

  }
}

@media only screen and (min-width: 1650px) {
  .card-container2 {
    margin-right: 0px !important;
  }
  .post-content {
    margin: 0 !important;
    margin-right: 0 !important;
  }
}

@media only screen and (min-width: 1700px) {
  .card-container2 {
    margin-right: -100px !important;
  }
  .post-content {
    margin: 0 !important;
    margin-right: 0 !important;
    
  }

  .card-container {
    margin-left: 12% !important;
  }
}

@media only screen and (max-width: 1456px) {
  .card-container {
    align-items: center;
    justify-content: center;
  }
  .card-container2 {
    margin-right: 40px !important;
  }
  .main_contianer {
    justify-content: center;
  }
}

@media only screen and (max-width: 3600px) {
 
  .main_contianer {
    width: 60% !important;
    margin-left: 20%;
}
}

@media only screen and (max-width: 3000px) {
 
  .main_contianer {
    width: 80% !important;
    margin-left: 10%;
}
}

@media only screen and (max-width: 2200px) {
 
  .main_contianer {
    width: 90% !important;
    margin-left: 5%;
}
}

@media only screen and (max-width: 1960px) {
 
  .main_contianer {
    width: 100%;
    margin-left: 0;
    justify-content: space-around !important;
}
}
.main_contianer{ 
  display: flex;
}

@media only screen and (max-width: 1286px) {
  .card-container {
    align-items: center;
    justify-content: center;
  }
  .card-container2 {
    margin-right: 60px !important;
  }
  .main_contianer {
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1500px) {
  .containeroverflow {
    width: 79% !important;
  }
}

.red {
  background-color: red !important;
}

.thumb {
  width: 15px !important;
  height: 15px !important;
  margin-top: -7px !important;
}

.label-slider {
  background-color: #3f51b5;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  height: 40px;
}

.card-container {
  width: 90% !important;
  margin-left: 5%;
}

.card-container2 {
  display: flex;
  flex: 0 0 33%;
  align-content: center;
  justify-content:center;
}

.red-color {
  color: red !important;
  stroke: red !important;
  fill: red !important;
  stroke: red !important;
}

.page_name {
  /* background-color: red !important; */
  font-weight: bold;
  font-size: 13.5px;
  margin-left: -6px;
}

.icon-heart {
  padding-left: 2px !important;
}

.chip_ad {
  color: white !important;
  font-weight: bold;
  font-size: 13.5px;
  border-radius: 8px !important;
}

.sidebar-gap {
  margin-right: -20px !important;
}

.top-chart {
  width: 100% !important;
  display: flex !important;
}

.apexcharts-datalabels{
  background-color: red !important;
  padding: 10px;
}

.date-range{
  order: none;
  border-bottom: 1px solid lightgray;
  background-color: transparent;
  color: black;
  font-size: 16px;
  padding: 8px;
  cursor: pointer;
  outline: none;
  width: 100%;
  color: gray;
}

.date-range:hover {
  border-color: black;
}

.DateRangePicker{
  display: flex;
  flex-direction: column;
  width: 300px;
  width: 700px;
  margin-left: -150px;
}